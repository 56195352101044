.flexible-height{
  @include respond-property('height', 240px, 200px, 160px, 140px, 124px, 92px);
}

.home-menu-box {
  z-index: 350;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;

  @include respond-property('height', 240px, 200px, 160px, 140px, 124px, 92px);

  svg.menu-background {
    position: absolute;
    top: -4px;
    left: 50%;
    right: 0;
    width: 1920px;
    height: 240px;
    margin-left: -960px;

    @include respond-down(XL){
      width: 1440px;
      height: 200px;
      margin-left: -720px;
    }

    @include respond-down(L){
      top: -40px;
    }

    @include respond-down(M){
      width: 160%;
      margin-left: 0;
      top: -60px;
      left: -30%;
    }

    @include respond-down(S){
      width: 200%;
      top: -76px;
      left: -50%;
    }

    @include respond-down(XS){
      width: 280%;
      top: -108px;
      left: -90%
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    .logo-wrapper {
      position: relative;
      z-index: 20;

      .kakadu-logo-svg {
        @include respond-property('width', 106px, 90px, 74px, 64px, 58px, 42px);
      }
    }

    svg.links {
      position: absolute;
      left: 50%;
      width: 858px;
      margin-left: -387px;
      top: 62px;

      @include respond-down(XL) {
        width: 720px;
        margin-left: -330px;
        top: 46px;
      }

      @include respond-down(L) {
        width: 700px;
        margin-left: -320px;
        top: 27px;
      }

      @include respond-down(M){
        display: none;
      }

      path {
        transition: fill 0.25s ease;
      }

      path.hover {
        fill: #3CE5CE
      }
    }
  }

}

.home-menu-box-wrapper {
  .mobile-menu-content {
    top: 0;
    padding-top: 150px;
    
    @include respond-down(S){
      padding-top: 130px;
    }

    @include respond-down(XS){
      padding-top: 100px;
    }
  }
}
