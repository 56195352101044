@import "settings";
@import "_common/grid";

// Index page
@import "_box/menu-box";
@import "_box/intro-box";
@import "_box/banners-box";
@import "_box/programs-box";


.index-page {
  .block-title {
    @include respond-property('font-size', 90px, 70px, 55px, 55px, 40px, 35px);
    font-family: "Monly", sans-serif;

    @include respond-down(S){
      line-height: 1em;
      margin-bottom: 0.2em;
    }
  }

  .block-subtitle {
    color: #373737;
  }
}


