.intro-box {
  @include respond-property('margin-bottom', 5px, 5px, 5px, 5px, 5px, 5px);
  width: 100%;
  position: relative;

  //@include respond-property('height', 900px, 800px, 700px, 600px, 500px, 400px);
  height: 100vh;

  @include respond-down(M){
    padding-top: 60px;
  }
  @include respond-down(S){
    padding-top: 70px;
  }
  @include respond-down(XS){
    padding-top: 0;
  }

  .arrow-buttons {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 6px 0;
    @include respond-property('width', 186px, 146px, 132px, 134px, 126px, 126px);
    @include respond-property('margin-bottom', 80px, 72px, 54px, 66px, 40px, 40px);

    .arrow-button {
      &.to-left {
      }

      &.to-right {
      }
    }
  }

  .intro-slides {
    @include respond-property('margin-bottom', 5px, 5px, 34px, 42px, 30px, 20px);
    overflow: hidden;

    .particle {
      display: none;
      position: absolute;
      z-index: 30;

      &.banana {
        width: 50px;
        height: 50px;
      }

      &.point {
        width: 24px;
        height: 24px;
      }
    }

    .item {
      background: #aaa  no-repeat center center;
      background-size: cover;
      background-image: attr(data-image url);
      cursor: pointer;
      padding-bottom: 47.9%;

      @include respond-down(XS){
        padding-bottom: 144%;
        //margin-top: 40px;
        margin-top: 0;
      }

      .container {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        height: 100%;
        z-index: 40;

        @include respond-down(XS){
          align-items: flex-end;
          justify-content: space-around;
        }
        
        .button {
          margin-bottom: 58px;
          @include respond-property('margin-bottom', 140px, 110px);
        }
      }
    }

    .owl-dots {
      bottom: 20px;
      display: none; // с 02.06.2020 прячем белые точки для переключения баннеров
      @include respond-property('bottom', 52px, 40px);

      position: absolute;
      width: 100%;
      text-align: center;

      .owl-dot {
        width: 22px;
        height: 22px;
        border: 3px solid #FFFFFF;
        opacity: 0.63;
        background: transparent;
        transition: background 0.35s ease, opacity 0.35s ease;
        border-radius: 20px;
        margin: 0 5px;

        @include respond-up(L){
          width: 15px;
          height: 15px;
          border: none;
          background: #FFF;
        }

        @include respond-down(XS){
          width: 16px;
          height: 16px;
          border: 4px solid #FFFFFF;
        }

        &.active {
          opacity: 1;
          background: #FFFFFF;
        }
      }
    }
  }
}
