.banners-box {
  @include respond-property('margin-bottom', 100px, 100px, 82px, 82px, 70px, 36px);
  width: 100%;
  overflow: hidden;

  .banner-carousel {
    @include respond-property('margin', 0 -360px, 0 -300px, 0 -260px, 0 -360px, 0 -420px, 0 -16px);

    .banner-wrapper {
      padding: 0 18px;
      margin-bottom: 92px;
      position: relative;

      @include respond-down(XL){
        padding: 0 13px;
        margin-bottom: 52px;
      }
      @include respond-down(XS){
        padding: 0 5%;
      }

      .button {
        position: absolute;
        @include respond-property('bottom', -28px, -25px, -20px, -25px, -25px, -25px);
        @include respond-property('margin-left', 38px, 30px, 22px, 22px, 22px, 20px);
      }

      .banner {
        @include respond-property('padding', 40px 38px, 32px 30px, 24px 22px, 24px 22px, 24px 22px, 26px 20px);
        @include respond-property('height', 350px, 290px, 250px, 254px, 254px, 268px);
        border-radius: 9px;
        overflow: hidden;
        background: #FFF;
        border: 5px solid #FCEC63;
        cursor: grab;
        position: relative;

        @include respond-down(XL){
          border-width: 3px;
        }

        .banner-media {
          position: absolute;
          right: 20px;
          top: 20px;
          bottom: 20px;
          width: 40%;
          background: no-repeat center center;
          background-size: contain;

          @include respond-down(XL){
            right: 8px;
            top: 16px;
            bottom: 16px;
            width: 36%;
          }
          @include respond-down(L){
            right: -24px;
            top: 6px;
            bottom: 6px;
            width: 42%;
          }
          @include respond-down(M){
            width: 36%;
          }
          @include respond-down(XS){
            display: none;
          }
        }

        .name {
          position: relative;
          height: 60px;
          @include respond-property('font-size', 25px, 20px, 18px, 18px, 18px, 16px);
          @include respond-property('margin-bottom', 24px, 20px, 18px, 18px, 18px, 20px);
          @include respond-property('height', 80px, 70px);
          text-transform: uppercase;
          color: #FFFFFF;
          font-weight: 800;
          letter-spacing: 0.1em;
          line-height: 1.25em;
          width: 70%;
          display: flex;

          @include respond-down(XL){
          }
          @include respond-down(M){
            width: 80%;
          }
          @include respond-down(XS){
            width: 90%;
          }

          .banner-image-round {
            width: 60px;
            height: 60px;
            @include respond-property('width',  80px, 70px);
            @include respond-property('height', 80px, 70px);
            border-radius: 150px;
            background: #6FECDB;
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            left: 0;
            top: 0;

            .img {
              width: 60px;
              height: 60px;
              @include respond-property('width',  80px, 70px);
              @include respond-property('height', 80px, 70px);
              background: no-repeat center center;
              background-size: 70%;
            }
          }

          .name-text {
            margin-left: 30px;
            @include respond-property('margin-left', 40px, 35px);
            padding: 0.2em 16px 0 50px;
            border-radius: 6px;
            background: #6FECDB;
            display: flex;
            align-items: center;
            width: 300px;

            @include respond-down(M){
              padding-left: 46px;
              padding-right: 24px;
            }
            @include respond-down(S){
              padding-left: 38px;
              padding-right: 14px;
            }
          }
        }

        .text {
          @include respond-property('max-height', 129px, 93px, 98px, 112px, 112px, 114px);
          font-size: 18px;
          line-height: 1.45em;
          width: 60%;
          overflow: hidden;

          @include respond-down(XL){
            width: 65%;
            font-size: 16px;
          }
          @include respond-down(L){
            font-size: 14px;
          }
          @include respond-down(M){
            width: 70%;
            font-size: 14px;
            line-height: 1.65em;
          }
          @include respond-down(XS){
            width: 100%;
          }
        }
      }
    }
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow-button {
      margin: 0 26px;

      @include respond-down(XL){
        margin: 0 20px;
      }
      @include respond-down(S){
        margin: 0 16px;
      }
    }
  }

}
